import React, { useState, useEffect } from "react";
import { Card, Button } from "antd";
import http from '../../api/http';
import api from '../../api';
import JobItemMini from "../JobItemMini"; // 本地的 JobItemMini 组件
import { useDebounceFn } from 'ahooks'

const RecommendFloat = ({ companyId ,positionId, title, type, initPageSize = 3 }) => {
  const [list, setList] = useState([]); // 岗位列表数据
  const [pageIndex, setPageIndex] = useState(1); // 当前页码
  const [pageSize] = useState(initPageSize); // 每页条数
  const [total, setTotal] = useState(0); // 数据总条数

  // 请求岗位数据
  const { run: fetchRecommendList } = useDebounceFn(async () => {
    try {
      const response = await http.get(api.getFloatJob(), {
        params: {
          pageIndex,
          pageSize,
          positionId,
          companyId,
          type
        }
      });

      if (response.code === 200) {
        setList(response.data || []); // 设置列表数据
        setTotal(response.pagination.totalRow || 0); // 设置总条数
      } else {
        console.error(response.message || "加载岗位数据失败");
      }
    } catch (error) {
      console.error("加载岗位数据失败", error);
    }
  }, {
    wait: 500,
  });

  // 切换分页
  const handlePageChange = (direction) => {
    setPageIndex((prev) => Math.max(1, prev + direction));
  };

  // 计算总页数
  const totalPages = Math.ceil(total / pageSize);

  // 初始化加载数据，监听页码变化
  useEffect(() => {
    fetchRecommendList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, positionId, type, companyId]);

  return (
    <Card
      title={<span className={"color-primary"}>{title}</span>} // 标题设置为红色
      bordered={false}
      style={{ width: 240 }}
      bodyStyle={{ padding: "16px" }}
    >
      {/* 推荐岗位列表 */}
      {
        list.length ? (
          <div>
            {list.map((item, index) => (
              <JobItemMini key={index} info={item}/>
            ))}
          </div>
        ) : (
          <div className="text-center">暂无岗位</div>
        )
      }


      {/* 自定义分页 */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        <Button
          size="small"
          disabled={pageIndex === 1}
          onClick={() => handlePageChange(-1)}
        >
          &lt; 上一页
        </Button>
        <span>
          {pageIndex} / {totalPages || 1}
        </span>
        <Button
          size="small"
          disabled={pageIndex === totalPages}
          onClick={() => handlePageChange(1)}
        >
          下一页 &gt;
        </Button>
      </div>
    </Card>
  );
};

export default RecommendFloat;
