import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Divider } from "antd";
import InfoDisplay from "../../../../components/InfoDisplay"; // 引用 InfoDisplay 组件
import http from "../../../../api/http";
import api from "../../../../api/index";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { getQueryParamNew } from "../../../../utils";

const RecruitmentPreview = ({ positionId }) => {
  const [data, setData] = useState({});
  const userInfo = useSelector((state) => state.user?.userInfo) || {};
  const clearInfo = useMemo(()=>{
    if(userInfo?.signState === 2) return true;
    const ui = getQueryParamNew('ui');
    return ui == 2;
  },[userInfo]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await http.get(api.getPositionRequirements(positionId));
        if (res.code === 200) {
          setData(res.data);
        } else {
          console.error("Error fetching data:", res.message);
        }
      } catch (error) {
        console.error("Fetch failed:", error);
      }
    };

    if (positionId) {
      fetchData();
    }
  }, [positionId]);

  return (
    <div className={classNames({'text-dim':!clearInfo})}>
      <Row gutter={16}>
        {/* 岗位基本情况 */}
        <Col span={24}>
          <h1>岗位基本情况</h1>
        </Col>
        <Col span={12}>
          <InfoDisplay title="该职位招聘了多久" value={data.recruitmentDate || "暂无数据"} />
        </Col>
        <Col span={12}>
          <InfoDisplay title="岗位产生原因" value={data.recruitmentReasonDesc || "暂无数据"} />
        </Col>
        <Col span={12}>
          <InfoDisplay title="岗位汇报对象" value={data.reportTo || "暂无数据"} />
        </Col>
        <Col span={12}>
          <InfoDisplay title="出差频率" value={data.travelFrequency || "暂无数据"} />
        </Col>
        <Col span={12}>
          <InfoDisplay title="期望到岗时间" value={data.serviceDate || "暂无数据"} />
        </Col>
        <Col span={12}>
          <InfoDisplay title="工作时间" value={data.workTime || "暂无数据"} />
        </Col>
        <Col span={12}>
          <InfoDisplay title="是否带团队" value={data.leadTeam === 1 ? "是" : "否"} />
        </Col>
        {data.leadTeam === 1 && (
          <Col span={12}>
            <InfoDisplay title="下属团队人数" value={data.teamSize || "暂无数据"} />
          </Col>
        )}
        <Col span={24}>
          <InfoDisplay title="加班情况" value={data.overtimeSituation || "暂无数据"} />
        </Col>
        <Col span={24}>
          <InfoDisplay title="其他需求分析" value={data.otherAnalysis || "暂无数据"} isRichText={true} />
        </Col>
        <Divider />
      </Row>

      {/* 人选要求 */}
      <Row gutter={16}>
        <Col span={24}>
          <h1>人选要求</h1>
        </Col>
        <Col span={12}>
          <InfoDisplay title="其他学历要求" value={data.ohterEducationalDesc || "暂无数据"} />
        </Col>
        <Col span={12}>
          <InfoDisplay title="性别要求" value={data.genderRequirementsDesc || "暂无数据"} />
        </Col>
        <Col span={12}>
          <InfoDisplay title="语言要求" value={data.languageRequirements || "暂无数据"} />
        </Col>
        <Col span={12}>
          <InfoDisplay title="语言要求熟练程度" value={data.languageProficiencyRequirements || "暂无数据"} />
        </Col>
        <Col span={12}>
          <InfoDisplay title="候选人所在地要求" value={data.locationRequirements || "暂无数据"} />
        </Col>
        <Col span={12}>
          <InfoDisplay title="对候选人年龄要求" value={data.ageRequirement || "暂无数据"} />
        </Col>
        <Col span={24}>
          <InfoDisplay title="以往工作经历要求" value={data.previousWorkExperience || "暂无数据"} isRichText={true} />
        </Col>
        <Col span={24}>
          <InfoDisplay title="其他要求（技能要求）" value={data.otherRequirements || "暂无数据"}  isRichText={true} />
        </Col>
        <Divider />
      </Row>

      {/* 福利待遇 */}
      <Row gutter={16}>
        <Col span={24}>
          <h1>福利待遇</h1>
        </Col>
        <Col span={12}>
          <InfoDisplay title="公积金缴纳情况" value={data.gjjPayment || "暂无数据"} />
        </Col>
        <Col span={12}>
          <InfoDisplay title="其他福利待遇" value={data.otherBenefit || "暂无数据"} />
        </Col>
        <Divider />
      </Row>

      {/* 面试流程 */}
      <Row gutter={16}>
        <Col span={24}>
          <h1>面试流程</h1>
        </Col>
        <Col span={12}>
          <InfoDisplay title="面试流程" value={data.interviewFlow || "暂无数据"} />
        </Col>
        <Col span={12}>
          <InfoDisplay title="面试方式" value={data.interviewTypeDesc || "暂无数据"} />
        </Col>
      </Row>
    </div>
  );
};

export default RecruitmentPreview;
