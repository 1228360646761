import React, { useState } from 'react';
import { Modal, Form, Input, Button, message, Image, Row, Col } from 'antd';
import api from '@/api'
import http from '@/api/http'

const BindPhoneModal = ({ visible, onCancel, ticket,onSucceed,mobile }) => {
  // 状态变量
  const [form] = Form.useForm(); // 创建表单实例
  const [imageCode, setImageCode] = useState(''); // 图形验证码
  const [captchaHashing, setCaptchaHashing] = useState('');
  const [timeLeft, setTimeLeft] = useState(0); // 倒计时
  const [isVerifying, setIsVerifying] = useState(false); // 是否正在获取验证码
  const [loading,setLoading] = useState(false);
  // timer,使用ref
  const timerRef = React.useRef(null);

  // 模拟图形验证码生成
  const generateImageCode = () => {
    const captchaHashingTemp = +new Date();
    setCaptchaHashing(captchaHashingTemp)
    const captcha = `${api.captcha}?id=${captchaHashingTemp}`
    // 模拟生成图形验证码，可以替换为后端接口调用
    setImageCode(captcha);
  };

  // 触发图形验证码生成
  const handleImageCodeClick = () => {
    generateImageCode();
  };

  // 验证手机号格式
  const validateMobile = (rule, value) => {
    if (!value) {
      return Promise.reject('手机号不能为空');
    }
    const reg = /^1[3-9]\d{9}$/;
    if (!reg.test(value)) {
      return Promise.reject('请输入有效的手机号');
    }
    return Promise.resolve();
  };

  // 校验图形验证码是否正确
  const validateImageCode = (rule, value) => {
    if (!value) {
      return Promise.reject('图形验证码不能为空');
    }
    return Promise.resolve();
  };

  // 获取验证码
  const getVerificationCode = () => {
    if (timeLeft > 0) {
      return;
    }
    // 校验手机号合法
    form.validateFields(['mobile', 'imageCode']).then(() => {
      // 获取表单手机号
      let postData = {
        mobile: form.getFieldValue('mobile'),
        smsTemplateType: 'LOGIN'
      }
      setIsVerifying(true);
      http.post(api.smsSend, postData).then(()=>{
        setIsVerifying(false);
      });
      // 模拟图形验证码验证通过后，开始倒计时
      clearInterval(timerRef.current);
      setTimeLeft(60);
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerRef.current);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    });

  };

  // 表单提交
  const handleSubmit = async (values) => {
    const body = {
      mobile: values.mobile,
      ticket,
      captchaHashing,
      captcha: values.imageCode,
      smsCaptcha: values.verificationCode,
    }
    setLoading(true);
    try{
      const res = await http.post(api.bindPhone(), body);
      setLoading(false);
      if (res.code === 200) {
        onSucceed(res.data.accessToken);
      }
      else message.error(res?.message || '绑定失败');
    }
    catch(e){
      setLoading(false);
    }
  };

  // 组件加载时生成图形验证码
  React.useEffect(() => {
    generateImageCode();
  }, []);

  return (
    <Modal
      title="绑定手机号"
      visible={visible}
      closable={false}
      maskClosable={false}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <Form form={form} onFinish={handleSubmit} initialValues={{mobile}}>
        {/* 手机号输入 */}
        <Form.Item
          name="mobile"
          label="手机号"
          rules={[{ required: true, message: '请输入手机号' }, { validator: validateMobile }]}
        >
          <Input placeholder="请输入手机号" disabled={!!mobile} />
        </Form.Item>

        {/* 图形验证码输入 */}
        <Form.Item
          name="imageCode"
          label="图形验证码"
          rules={[{ required: true, message: '请输入图形验证码' }, { validator: validateImageCode }]}
        >
          <Row gutter={8}>
            <Col span={16}>
              <Input placeholder="请输入图形验证码" />
            </Col>
            <Col span={8}>
              <Image
                width={80}
                src={imageCode}
                alt="验证码"
                preview={false}
                onClick={handleImageCodeClick}
                style={{ cursor: 'pointer' }}
              />
            </Col>
          </Row>
        </Form.Item>

        {/* 验证码输入 */}
        <Form.Item
          name="verificationCode"
          label="验证码"
          rules={[{ required: true, message: '请输入验证码' }]}
        >
          <Row gutter={8}>
            <Col span={16}>
              <Input placeholder="请输入验证码" />
            </Col>
            <Col span={8}>
              <Button
                type="primary"
                onClick={getVerificationCode}
                disabled={timeLeft > 0}
                loading={isVerifying}
              >
                {timeLeft > 0 ? `${timeLeft}s` : '获取验证码'}
              </Button>
            </Col>
          </Row>
        </Form.Item>

        {/* 绑定按钮 */}
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            绑定手机号
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BindPhoneModal;
