/**
 * Created by Jachin on 2019/6/15.
 */


const baseUrl = process.env.REACT_APP_SECRET_CODE;

// 设置api
function setApi(path) {
  return baseUrl + path;
}


export default {
  /**
   * 基础信息
   * */
  dictionaries: parentCode => `${baseUrl}/headhunter/dictionaries/${parentCode}`,// getDictionariesByParentCode
  getAllDic: setApi(`/headhunter/dictionaries`),// getDictionariesByParentCode
  smsSend: setApi(`/headhunter/message/sms/send`),// 发送验证码
  emailSend: setApi(`/headhunter/users/change/email`),// 发送电子邮箱验证码

  /**
   * 统计
   * */
  positionRecommendTotal: setApi(`/headhunter/data-center/position-recommend-total`),// 推荐数据统计
  kpiTotal: setApi(`/headhunter/data-center/kpi`),// 业绩

  /**
   * 用户
   * */
  captcha: setApi(`/headhunter/captcha.png`), // 验证码
  register: setApi(`/headhunter/register`), // register
  login: setApi(`/headhunter/headhunterOauth2/token`), // 登录，获取授权令牌
  logout: setApi(`/headhunter/headhunterOauth2/logout`), // 退出系统
  forgetPassword: setApi(`/headhunter/users/forget-password`), // 忘记密码，通过短信重置
  changePassword: setApi(`/headhunter/users/change-password`), // 修改自己的密码
  registerSelf: setApi(`/headhunter/users/self`), // 用户个人信息
  userFilling: setApi(`/headhunter/users/filling`), // filling
  wechatBind: setApi(`/headhunter/wechat/binding`), // 微信绑定
  wechatUnBind: setApi(`/headhunter/wechat/unbunding`), // 微信解绑
  wechatLogin: setApi(`/headhunter/wechat/login`), // 微信登录
  changeMobile: setApi(`/headhunter/users/change/mobile`), // 更换手机号
  changeEmail: setApi(`/headhunter/users/change/email`), // 更换手机号
  changeMiniProgram: (smsCode, mobile, imageCaptcha, smsCocaptchaHashingde) => `${baseUrl}/headhunter/users/bind/seeker?smsCode=${smsCode}&mobile=${mobile}&imageCaptcha=${imageCaptcha}&captchaHashing=${smsCocaptchaHashingde}`,
  miniProgramUnBind: setApi(`/headhunter/users/unbind/seeker`), // 小程序解绑

  /**
   * 简历
   * */
  esIdPut: esId => `${baseUrl}/headhunter/resume/preview/${esId}`, // 预览
  resume: setApi(`/headhunter/elastic/search`), // 简历列表
  resumeCreate: setApi(`/headhunter/resume`), // 根据附件简历增加用户简历
  resumeDetail: resumeId => `${baseUrl}/headhunter/resume/${resumeId}/2`, // 简历详情
  resumeUpdate: (resumeId, esId) => `${baseUrl}/headhunter/resume/${resumeId}/${esId}`, // 简历修改
  resumeDel: (resumeId, esId) => `${baseUrl}/headhunter/resume/${resumeId}/${esId}`, // 简历删除
  resumeAddAttachment: (resumeId, esId) => `${baseUrl}/headhunter/resume/${resumeId}/${esId}/attachment`, // 为简历添加一个新的附件



  resumeDelAttachment: (resumeId, attachmentId,esId) => `${baseUrl}/headhunter/resume/${resumeId}/attachment/${attachmentId}/${esId}`, // 简历附件删除



  resumeWithPosition: positionId => `${baseUrl}/headhunter/resume/position/${positionId}`, // 候选人列表
  resumeTalkGet: (resumeId, resumeLibraryType) => `${baseUrl}/headhunter/resume-talk/${resumeId}/${resumeLibraryType}`, // 沟通记录列表
  resumeTalkAdd: (resumeId, resumeLibraryType) => `${baseUrl}/headhunter/resume-talk/${resumeId}/${resumeLibraryType}`, // 增加沟通记录
  resumeLabel: setApi(`/headhunter/label/findLabelNameList`), // 简历标签
  resumeLabelGroup: setApi(`/headhunter/label/findLabelForTree`), // 简历标签组合
  getResumePreview: (userResumeId, resumeType, resumeLibraryType) => `${baseUrl}/headhunter/job-seekers/preview?resumeUserId=${userResumeId}&resumeType=${resumeType}&resumeLibraryType=${resumeLibraryType}`, // 获得简历详情
  jobSeekersDownload: setApi(`/headhunter/job-seekers/download`), // 简历下载
  searchArea:setApi(`/headhunter/homeCity/all`), // 城市数据

  /**
   * 邀请码
   * */
  invitation: setApi(`/headhunter/invitation-codes`), // 列表

  /**
   * 猎头简历推荐
   * */
  positionRecommendation: setApi(`/headhunter/positionRecommendation`), // 简历推荐投递创建
  positionRecommendationAll: setApi(`/headhunter/positionRecommendation/headhunterAllload`), // 猎头根据自己id 信息进行查询所有推荐的简历
  // positionRecommendationSingle: setApi(`/headhunter/positionRecommendation/load`), // 猎头查询单个数据详情
  positionRecommendationSingle: deliveryId => `${baseUrl}/headhunter/delivery/common/getRecommendReason/${deliveryId}`, // 猎头查询单个数据详情
  // 遗弃positionRecommendWithPosition: setApi(`/headhunter/positionRecommendation/position-recommend-inquiry`), // 根据职位信息进行查询对应的投递记录信息
  positionRecommendWithPosition: positionId => `${baseUrl}/headhunter/delivery/common/positionDeliveryList/${positionId}`, // 根据职位信息进行查询对应的投递记录信息
  // 遗弃positionRecommendWithResume: setApi(`/headhunter/positionRecommendation/resume-recommend-inquiry`), // 根据简历库用户查询该用户的投递记录
  deliveryRecommendationLog: (userId, resumeLibraryType) => `${baseUrl}/headhunter/delivery/common/${userId}/${resumeLibraryType}`, // 根据简历库用户查询该用户的投递记录
  positionRecommendWithResume: setApi(`/headhunter/delivery/common/headhunterAllload`), // 根据简历信息进行查询对应推荐记录
  indexRecommend: setApi(`/headhunter/positionRecommendation/recommended-record`), // 首页的推荐记录
  RecordListByDelivery: deliveryId => `${baseUrl}/headhunter/delivery/common/getRecordListByDeliveryId/${deliveryId}`, // 操作记录
  /** 根据推荐记录获取候选人基本信息 */
  recommendPersonInfo: id => `${baseUrl}/headhunter/positionRecommendation/load/${id}`,
  /** 根据简历id获取所有的推荐记录 */
  recommendList: resumeId => `${baseUrl}/headhunter/positionRecommendation/deliveryInfo/resume/${resumeId}`,
  /** 修改状态 */
  recommendChangeState:(id,state) => `/headhunter/positionRecommendation/${id}/${state}`,
  /** 猎头用户获取能够处理的状态标签 */
  recommendStateList: (currentState) => `/headhunter/positionRecommendation/state/process?currentState=${currentState}`,
  /**
   * 职位查询
   * */
  positionListV1: setApi(`/headhunter/position/dimfindallpriority`), // 模糊查询职位信息
  positionList: setApi(`/headhunter/position/list`), // 模糊查询职位信息
  positionDetail: positionId => `${baseUrl}/headhunter/position/${positionId}`, // 获取单个职位详情
  positionRecommend: setApi(`/headhunter/position/recommendation-position`), // 根据拿到用户的简历ID信息进行查询职位是否可以投递
  positionEnterprise: setApi(`/headhunter/position/enterprise-position`), // 根据企业查询公司职位列表信息
  companyDetail: companyId => `${baseUrl}/headhunter/company/${companyId}`, // 获取公司详情
  positionHot: setApi(`/headhunter/position/hot-topic-position`), // 热招职位推荐
  positionHotIndex: setApi(`/headhunter/position/condition-recommended`), // 首页热招职位推荐
  positionArea: setApi(`/headhunter/position/area`), // 职位地址
  positionTag: setApi(`/headhunter/label/findLabelNameList?type=3&pageSize=100`), // 职位标签

  sharedImgs:setApi(`/headhunter/position/sharedImg`),  //获取分享图片


  /**
   * 猎头查询账单信息
   * */
  billTotal: setApi(`/headhunter/check/amountOfStatistical`), // 统计
  billList: setApi(`/headhunter/check/conditionQuery`), // 条件查询账单信息。返回20条数据信息，如果没有条件默认返回全部数据
  rateBoard: setApi(`/headhunter/check/rate-board`), // 费率看版

  /**
   * 职位收藏
   * */
  collect: setApi(`/headhunter/collect`), // 新增收藏职位信息
  collectDel: collectorNumber => `${baseUrl}/headhunter/collect/${collectorNumber}`, // 删除收藏职位信息
  //collectDetail: setApi(`/headhunter/collect/{collectorNumber}`), // 根据收藏职位编号进行查询单个数据信息(收藏职位的详情)
  collectAll: setApi(`/headhunter/collect/findAll`), // 根据猎头ID编号进行查询所有数据信息（我收藏的职位）


  /**
   * 公司列表 相关接口
   * */
  companyList: setApi(`/headhunter/company/list`), // 公司列表
  collectCompany: setApi(`/headhunter/company/collect`), // 对公司感兴趣
  cancellateCompany: companyId =>`${baseUrl}/headhunter/company/${companyId}`, //取消对公司感兴趣
  agreementInfo: companyId =>`${baseUrl}/headhunter/company/agreement/${companyId}`,//合同信息，合作明细
  getPosition: (id) => setApi(`/headhunter/position/${id}`), // 职位详情
  postPosition: () => setApi(`/headhunter/position`), // 编辑职位
  editPosition: () => setApi(`/headhunter/position`), // 新增职位
  calculationCommission: setApi(`/headhunter/agreementCooperationWays/calculationCommission`), // 计算佣金
  getWorkArea: (companyId) => setApi(`/headhunter/companyAddressMgr/company/${companyId}`), // 获取工作地址
  getCompanyDept: () => setApi(`/headhunter/companyDept`), // 获取公司部门列表
  addCompanyDept: () => setApi(`/headhunter/companyDept`), // 获取公司部门列表
  getCompanyList: () => setApi(`/headhunter/position/companyNames`), // 获取公司列表
  getTreeAll: () => setApi(`/headhunter/PositionType/getTreeAll`), // 获取职位类型
  getMyPosition: () => setApi(`/headhunter/position/users/positionList`), // 获取我的职位列表
  getMyCompany: () => setApi(`/headhunter/company/companyInfos`), // 获取我的职位列表
  changePositionState: (id, approveState) => setApi(`/headhunter/position/updateState/${id}/${approveState}`), // 更新我的职位上下架
  changePositionDate: (id) => setApi(`/headhunter/position/updateDate/${id}`), // 更新我的职位有效期
  getProvinces: () => setApi(`/headhunter/area/provinces`), // 获取省市区
  getProvincesChildren: (parentId) => setApi(`/headhunter/area/parent/${parentId}`), // 获取省市区
  getAreaTextList: () => setApi(`/headhunter/wapi/gdaddress`), // 获取工作地点
  getPark: (id) => setApi(`/headhunter/park/${id}`), // 获取园区
  createWorkArea: () => setApi(`/headhunter/companyAddressMgr`), // 新增地址
  getAdminList: () => setApi(`/headhunter/users/chargers`), // 获取负责人列表
  getAgreementList: (companyId) => setApi(`/headhunter/agreement/${companyId}`), // 获取公司合同列表
  getAgreementDetail: (agreementId) => setApi(`/headhunter/agreementCooperationWays/${agreementId}`), // 获取合同明细
  getPositionRequirements: (positionId) => setApi(`/headhunter/positionRequirements/preview/${positionId}`), // 获取需求分析
  editPositionRequirements: () => setApi(`/headhunter/positionRequirements`), // 获取需求分析
  editCompanyRequirements: () => setApi(`/headhunter/companyRequirements`), // 编辑公司需求分析
  getCompanyRequirements: (companyId) => setApi(`/headhunter/companyRequirements/${companyId}`), // 获取公司需求分析
  getSinglePosition: (id) => setApi(`/headhunter/position/${id}`), // 获取单个职位信息
  getCompanyInfo: (id) => setApi(`/headhunter/company/${id}`), // 获取单个职位信息
  setRequireRead: (positionRequireId) => setApi(`/headhunter/positionRequirements/preview/${positionRequireId}`), // 获取单个职位信息
  getRecommendHistory: (positionId) => setApi(`/headhunter/positionRecommendation/deliveryInfo/${positionId}`), // 获取单个职位信息
  getFloatJob: () => setApi(`/headhunter/positionSide/list`), // 获取单个职位信息
  upload: () => setApi(`/headhunter/attachments/annex/BRA_ANNEX`), // 获取单个职位信息
  uploadSuffix: () => setApi(`/headhunter/braAnnex`),
  deleteSuffix: (id) => setApi(`/headhunter/braAnnex/${id}`),
  getSuffix: (companyId) => setApi(`/headhunter/braAnnex/company/${companyId}`),
  exportPosition: () => setApi(`/headhunter/position/export`),
  getResumeState: () => setApi(`/headhunter/positionRecommendation/state/process`),
  setResumeState: (id, state) => setApi(`/headhunter/positionRecommendation/${id}/${state}`),
  getSubAccountList: () => setApi(`/headhunter/subAccount/subs`),
  getSubAccount: (id) => setApi(`/headhunter/subAccount/sub/${id}`),
  editSubAccount: () => setApi(`/headhunter/subAccount/sub`),
  getRecommendedData: () => setApi(`/headhunter/dataCenter/recommendedData`),
  getDockingData: () => setApi(`/headhunter/dataCenter/docking`),
  getQrcode: () => setApi(`/headhunter/scanWx`),
  checkWxLogin: () => setApi(`/headhunter/scanWx/checkLogin`),
  changeSubAccountState: (userId, state) => setApi(`/headhunter/subAccount/sub/${userId}/${state}`),
  bindPhone: () => setApi(`/headhunter/scanWx/bindMobile`),
  getPreviewUrl: (supplierType) => setApi(`/headhunter/fadada/getTeamplateDetail/${supplierType}`),
  getSignPreviewUrl: (taskId) => setApi(`/headhunter/fadada/getSigningUrl/${taskId}`),
  downloadPreviewUrl: (taskId) => setApi(`/headhunter/fadada/getSigningUrl/${taskId}`),
  getSignIdByType: (supplierType) => setApi(`/headhunter/supplierInfo/${supplierType}`),
  createSignTask: () => setApi(`/headhunter/fadada/createSignTask`),
  queryCompanyName: () => setApi(`/headhunter/supplierInfo/getSupplierNames`),
  agreementList: () => setApi(`/headhunter/supplierInfo/agreementList`),
  constrctSmsLink:(taskId) => setApi(`/headhunter/fadada/urge/${taskId}`),
  headhunterNames:(key)=>setApi(`/headhunter/subAccount/subNames?key=${key}`),
}





