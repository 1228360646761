import React, { Component } from 'react';

// SplitLine 类组件
class SplitLine extends Component {
  static defaultProps = {
    color: '#e0e0e0', // 默认颜色
    height: '1px', // 默认高度
    width: '100%', // 默认宽度
    margin: '20px 0', // 默认外边距
  };
  render() {
    const { color, height, width, margin, ...otherProps } = this.props;
    const style = {
      backgroundColor: color,
      height: height,
      width: width,
      margin: margin,
    };

    return <div style={style} {...otherProps} />;
  }
}

export default SplitLine;
