/**
 * Created by Jachin on 2019/4/11.
 */
import React, { Component } from 'react'
import './index.scss'
import CustomTabs from '../../components/CustomTabs'
import CustomButton from '../../components/CustomButton'
import CustomToast from '../../components/CustomToast'
import LoginInput from './components/LoginInput'
import LoginButton from './components/LoginButton'
import SMSButton from './components/SMSButton'
import PasswordValidTips from './components/PasswordValidTips'
import http from '../../api/http.js'
import api from '../../api/index.js'
import {setCookie, setStorage, getStorage, Base64, isPhoneNum} from '../../utils/index'
import WechatCode from '@/components/WechatCode/index.jsx';

export default class Index extends Component {
  constructor (props) {
    super(props);
    setCookie('token', '', -1);
    this.state = {
      loginType: '3',// 1 密码登录，2 验证码登录，3 微信登录，4 注册，5 注册完成
      captcha: '',
      holdAccount: {// 记住账号
        account: false,
        mobile: false,
        registerProtocol: false,// 注册协议
      },
      registerForm: {// 注册表单
        mobile: "",
        captchaHashing: "",
        imageCaptcha: "",
        smsCaptcha: "",
        password: "",
        confirmPassword: "",
        invitationCode: "",
      },
      registerFormRules: {// 注册表单验证规则
        mobile: {
          required: true,// 是否必填
          inputTips: '手机号码（11位数）',// 常规提示语
          errorTips: '',// 错误提示
        },
        imageCaptcha: {
          required: true,
          inputTips: '图形验证码',
          errorTips: '',
        },
        smsCaptcha: {
          required: true,
          inputTips: '短信验证码',
          errorTips: '',
        },
        password: {
          required: true,
          inputTips: '密码长度6~20位',
          errorTips: '',
        },
        confirmPassword: {
          required: true,
          inputTips: '重复密码',
          errorTips: '',
        },
        invitationCode: {
          required: true,
          inputTips: '邀请码',
          errorTips: '',
        },
      },
      loginFormAccount: {// 账号登录表单
        username: "",
        password: "",
        userType: "HEADHUNTER",
        grantType: "password",
      },
      loginFormAccountRules: {// 账号登录表单验证规则
        username: {
          required: true,// 是否必填
          inputTips: '手机号码（11位数）',// 常规提示语
          errorTips: '',// 错误提示
        },
        password: {
          required: true,
          inputTips: '密码长度6~20位',
          errorTips: '',
        },
      },
      loginFormMobile: {// 手机号登录表单
        mobile: "",
        imageCaptcha: "",
        captchaHashing: "",
        captcha: "",
        userType: "HEADHUNTER",
        grantType: "sms"
      },
      loginFormMobileRules: {// 手机号登录表单验证规格
        mobile: {
          required: true,// 是否必填
          inputTips: '手机号码（11位数）',// 常规提示语
          errorTips: '',// 错误提示
        },
        imageCaptcha: {
          required: true,
          inputTips: '图形验证码',
          errorTips: '',
        },
        captcha: {
          required: true,
          inputTips: '短信验证码',
          errorTips: '',
        },
      },
      registerPswTipsShow: false,// 注册密码强度提示面板,
    }
  }
  componentDidMount() {
    let loginAccount = getStorage('loginAccount');
    let loginMobile = getStorage('loginMobile');
    let loginFormAccount = this.state.loginFormAccount;
    let loginFormMobile = this.state.loginFormMobile;
    let holdAccount = this.state.holdAccount;
    if (loginAccount) {
      loginFormAccount.username = loginAccount;
      holdAccount.account = true;
    }
    if (loginMobile) {
      loginFormMobile.mobile = loginMobile;
      holdAccount.mobile = true;
    }
    this.setState({
      loginFormAccount,
      loginFormMobile,
      holdAccount,
    })
  }
  // 操作登录类型
  handleItem = (type) => {
    if (type === this.state.loginType) {
      return false
    }
    this.setState({
      loginType: type
    })
    // 当登录类型发生改变时，需要把这些 ref 清空，避免内存泄漏
    if (type !== this.state.loginType) {
      this.registerInpuitRef = [];
      this.loginAccountInpuitRef = [];
      this.loginMobileInpuitRef = [];
    }
    if (type === '2' || type === '4') {
      this.createCaptcha(type)
    }
  }

  // 表单字段更新
  inputChange(form, key, value) {
    // 如果是记住账号的表单，需要处理一下value值
    if (form === 'holdAccount') {
      value = value.target.checked;
    }
    this.setState({
      [form]: Object.assign({}, this.state[form], {[key]: value })
    });
  }

  // 登录回车事件
  inputKeyUp = (ev) => {
    if (ev.keyCode === 13) {
      this.loginSubmit();
    }
  }

  // 注册回车事件
  inputRegisterKeyUp = (ev) => {
    if (ev.keyCode === 13) {
      this.registerSubmit();
    }
  }

  // 组合验证码
  createCaptcha = (loginType) => {
    let id = +new Date();
    let otherObj = {};
    if (loginType === '2') {
      otherObj = {loginFormMobile:{...this.state.loginFormMobile,captchaHashing:id}}
    } else if (loginType === '4') {
      otherObj = {registerForm:{...this.state.registerForm,captchaHashing:id}}
    }
    this.setState({
      captcha: `${api.captcha}?id=${id}`,
      ...otherObj,
    })
  }

  // 注册密码修改时回调
  registerPswChange = (key, value) => {
    if (value !== '' && value !== undefined) {
      this.setState({
        registerPswTipsShow: true
      }, () => {
        let registerFormRules = this.state.registerFormRules;
        registerFormRules.password.errorTips = this.passwordInpuitRef.onChange(value);
        this.setState({
          registerFormRules
        })
      })
    } else {
      this.setState({
        registerPswTipsShow: false
      })
    }
    this.inputChange('registerForm', key, value)
  }

  // 注册密码输入框失去焦点回调
  registerPswBlur = () => {
    this.setState({
      registerPswTipsShow: false
    })
  }

  // 注册表单的ref
  registerInputOnRef = (ref) => {
    if (!this.registerInpuitRef) {
      this.registerInpuitRef = []
    }
    this.registerInpuitRef.push(ref);
  }

  // 注册表单密码输入框的ref
  passwordInputOnRef = (ref) => {
    this.passwordInpuitRef = ref;
  }

  // 账号登录表单的ref
  loginAccountInputOnRef = (ref) => {
    if (!this.loginAccountInpuitRef) {
      this.loginAccountInpuitRef = []
    }
    this.loginAccountInpuitRef.push(ref);
  }

  // 验证码登录表单的ref
  loginMobileInputOnRef = (ref) => {
    if (!this.loginMobileInpuitRef) {
      this.loginMobileInpuitRef = []
    }
    this.loginMobileInpuitRef.push(ref);
  }

  // 清除错误提示
  clearErrorTips = (rules, key) => {
    let currentRules = this.state[rules];
    currentRules[key].errorTips = '';
    this.setState({
      [rules]: currentRules
    })
  }

  // 注册提交
  registerSubmit = () => {
    let pass = true;
    // 验证必填
    this.registerInpuitRef.forEach(value => {
      if (!value.validRequire()) {
        pass = false;
      }
    });
    // 验证手机号
    if (this.state.registerForm.mobile && !isPhoneNum(this.state.registerForm.mobile)) {
      pass = false;
      let registerFormRules = this.state.registerFormRules;
      registerFormRules.mobile.errorTips = '请输入正确的手机号';
      this.setState({
        registerFormRules
      })
    }
    // 验证密码
    if (this.state.registerFormRules.password.errorTips) {
      pass = false;
    }
    // 验证重复密码
    if (this.state.registerForm.confirmPassword && this.state.registerForm.confirmPassword !== this.state.registerForm.password) {
      pass = false;
      let registerFormRules = this.state.registerFormRules;
      registerFormRules.confirmPassword.errorTips = '两次密码不一致';
      this.setState({
        registerFormRules
      })
    }
    if (!pass) {
      return false
    }
    if (!this.state.holdAccount.registerProtocol) {
      CustomToast('请先阅读并同意《内推圈注册协议》');
      return false
    }
    http.post(api.register, this.state.registerForm)
      .then(res => {
        if (res.code === 200) {
          setCookie('token', res.data.accessToken, 24)
          this.handleItem('5')
        } else {
          CustomToast(res.message)
        }
      })
  }

  // 登录提交
  loginSubmit = () => {
    let postData = {};
    let pass = true;
    if (this.state.loginType === '1') {
      // 验证必填
      this.loginAccountInpuitRef.forEach(value => {
        if (!value.validRequire()) {
          pass = false;
        }
      });
      // 验证手机号
      if (this.state.loginFormAccount.username && !isPhoneNum(this.state.loginFormAccount.username)) {
        pass = false;
        let loginFormAccountRules = this.state.loginFormAccountRules;
        loginFormAccountRules.username.errorTips = '请输入正确的手机号';
        this.setState({
          loginFormAccountRules
        })
      }
      if (!pass) {
        return false
      }
      postData = JSON.parse(JSON.stringify(this.state.loginFormAccount));
      postData.password = new Base64().encode(`${postData.username}:${postData.password}`)
    } else if (this.state.loginType === '2') {
      // 验证必填
      this.loginMobileInpuitRef.forEach(value => {
        if (!value.validRequire()) {
          pass = false;
        }
      });
      // 验证手机号
      if (this.state.loginFormMobile.mobile && !isPhoneNum(this.state.loginFormMobile.mobile)) {
        pass = false;
        let loginFormMobileRules = this.state.loginFormMobileRules;
        loginFormMobileRules.mobile.errorTips = '请输入正确的手机号';
        this.setState({
          loginFormMobileRules
        })
      }
      if (!pass) {
        return false
      }
      postData = this.state.loginFormMobile;
    }


    http.post(api.login, postData)
      .then(res => {
        if (res.code === 200) {
          setCookie('token', res.data.accessToken, 24);
          if (res.data.state === 'INITIALIZED') {
            window.location.hash = '#/supplement-user-info-tips'
          } else if (res.data.state === 'AUDITING') {
            window.location.hash = '#/user-audit';
          } else {
            window.location.hash = '#/home/index';
          }
          setStorage('userInfo', JSON.stringify(res.data) )
          if (this.state.loginType === '1' && this.state.holdAccount.account) {
            setStorage('loginAccount', postData.username)
          } else if (this.state.loginType === '2' && this.state.holdAccount.mobile) {
            setStorage('loginMobile', postData.mobile)
          }
        } else {
          CustomToast(res.message)
        }
      })
  }

  // 前往重置密码
  toResetPassword = () => {
    window.location.hash = '#/reset-password'
  }
  // 前往完善个人信息
  toEditInfo = () => {
    window.location.hash = '#/supplement-user-info'
  }
  onWechatSucceed = (accessToken) => {
    setCookie('token',accessToken, 24);
    window.location.hash = '#/home/index';
  }
  render () {
    return (
      <div className="page-login">
        <div className="login-center">
          <div className="login-center-wrap">
            <div className="logo">
              <div className="img-wrap">
                <img className="img" src={require('../../images/logo.png')} alt=""/>
              </div>
              <p className="text">猎头系统</p>
            </div>
            {/*账号登录和手机号登录*/}
            {
              (this.state.loginType === '1' || this.state.loginType === '2') && (
                <div className="login-box-wrap">
                  <div className="login-switch-wrap">
                    <CustomTabs active={this.state.loginType} onChange={this.handleItem}>
                      <CustomTabs.CustomTabPane label="账号密码登录" name="1"/>
                      <CustomTabs.CustomTabPane label="手机验证码登录/注册" name="2"/>
                    </CustomTabs>
                  </div>
                  {/*账号密码登录*/}
                  {
                    this.state.loginType === '1' && (
                      <>
                        <div className="login-form-wrap">
                          <p className='login-tip'>第一次使用该平台的用户，请使用手机验证码登录/注册</p>
                          <div className="login-form-cell">
                            <LoginInput
                              placeholder="请输入手机号"
                              icon="&#xe715;"
                              tips={this.state.loginFormAccountRules.username.inputTips}
                              errorTips={this.state.loginFormAccountRules.username.errorTips}
                              clearError={this.clearErrorTips.bind(this, 'loginFormAccountRules', 'username')}
                              require={true}
                              onRef={this.loginAccountInputOnRef}
                              maxLength={11}
                              value={this.state.loginFormAccount.username}
                              onChange={this.inputChange.bind(this, 'loginFormAccount', 'username')}/>
                          </div>
                          <div className="login-form-cell">
                            <LoginInput
                              placeholder="请输入密码"
                              icon="&#xe63a;"
                              tips={this.state.loginFormAccountRules.password.inputTips}
                              errorTips={this.state.loginFormAccountRules.password.errorTips}
                              clearError={this.clearErrorTips.bind(this, 'loginFormAccountRules', 'password')}
                              require={true}
                              onRef={this.loginAccountInputOnRef}
                              maxLength={20}
                              type="password"
                              value={this.state.loginFormAccount.password}
                              onChange={this.inputChange.bind(this, 'loginFormAccount', 'password')}
                              onKeyUp={this.inputKeyUp}/>
                          </div>
                          <LoginButton onClick={this.loginSubmit}>登录</LoginButton>
                          <div className="login-form-links">
                            <label>
                              <input type="checkbox" checked={this.state.holdAccount.account}
                                    onChange={this.inputChange.bind(this, 'holdAccount', 'account')}/>
                              <p className="icon-wrap">
                                <i className="iconfont">&#xe60f;</i>
                              </p>
                              <p className="text">记住账号</p>
                            </label>
                            <p className="login-form-forgot-button" onClick={this.toResetPassword}>忘记密码</p>
                          </div>
                        </div>
                      </>
                    )
                  }
                  {/*手机验证码登录*/}
                  {
                    this.state.loginType === '2' && (
                      <div className="login-form-wrap">
                        <div className="login-form-cell">
                          <LoginInput
                            placeholder="请输入手机号"
                            icon="&#xe715;"
                            tips={this.state.loginFormMobileRules.mobile.inputTips}
                            errorTips={this.state.loginFormMobileRules.mobile.errorTips}
                            clearError={this.clearErrorTips.bind(this, 'loginFormMobileRules', 'mobile')}
                            require={true}
                            onRef={this.loginMobileInputOnRef}
                            maxLength={11}
                            value={this.state.loginFormMobile.mobile}
                            onChange={this.inputChange.bind(this, 'loginFormMobile', 'mobile')}/>
                        </div>
                        <div className="login-form-cell">
                          <LoginInput
                            placeholder="请输入图形验证码"
                            icon="&#xe6a2;"
                            tips={this.state.loginFormMobileRules.imageCaptcha.inputTips}
                            errorTips={this.state.loginFormMobileRules.imageCaptcha.errorTips}
                            clearError={this.clearErrorTips.bind(this, 'loginFormMobileRules', 'imageCaptcha')}
                            require={true}
                            onRef={this.loginMobileInputOnRef}
                            maxLength={4}
                            value={this.state.loginFormMobile.imageCaptcha}
                            onChange={this.inputChange.bind(this, 'loginFormMobile', 'imageCaptcha')}/>
                          <div className="captcha-wrap">
                            <img src={this.state.captcha} alt="" className="captcha" onClick={this.createCaptcha.bind(this,'2')}/>
                          </div>
                        </div>
                        <div className="login-form-cell">
                          <LoginInput
                            placeholder="请输入短信验证码"
                            icon="&#xe62c;"
                            tips={this.state.loginFormMobileRules.captcha.inputTips}
                            errorTips={this.state.loginFormMobileRules.captcha.errorTips}
                            clearError={this.clearErrorTips.bind(this, 'loginFormMobileRules', 'captcha')}
                            require={true}
                            onRef={this.loginMobileInputOnRef}
                            maxLength={6}
                            value={this.state.loginFormMobile.captcha}
                            onChange={this.inputChange.bind(this, 'loginFormMobile', 'captcha')}
                            onKeyUp={this.inputKeyUp}/>
                          <SMSButton disabled mobile={this.state.loginFormMobile.mobile}/>
                        </div>
                        <LoginButton onClick={this.loginSubmit}>登录/注册</LoginButton>
                        <div className="login-form-links">
                          <label>
                            <input type="checkbox" checked={this.state.holdAccount.mobile}
                                   onChange={this.inputChange.bind(this, 'holdAccount', 'mobile')}/>
                            <p className="icon-wrap">
                              <i className="iconfont">&#xe60f;</i>
                            </p>
                            <p className="text">记住账号</p>
                          </label>
                          {/*<p className="login-form-forgot-button" onClick={this.toResetPassword}>忘记密码</p>*/}
                        </div>
                      </div>
                    )
                  }
                  <div className="third-login">
                    <div className="title">
                      <p className="title-text">第三方账号登录/注册</p>
                    </div>
                    <div className="wechat-icon" onClick={this.handleItem.bind(this, '3')}>
                      <i className="iconfont">&#xe641;</i>
                    </div>
                    <p style={{fontSize:14,color:'#778b99',paddingTop:10}}>扫描登录/注册</p>
                  </div>
                </div>
              )
            }
            {/*微信登录*/}
            {
              this.state.loginType === '3' && (
                <div className="login-box-wrap">
                  <WechatCode onSucceed={this.onWechatSucceed}  />
                  <div className="qrcode-switch-button-wrap">
                    <p className="qrcode-switch-button" onClick={this.handleItem.bind(this, '1')}>账号登录</p>
                    <p className="qrcode-switch-button" onClick={this.handleItem.bind(this, '2')}>手机号登录</p>
                  </div>
                </div>
              )
            }
            {/*注册*/}
            {
              this.state.loginType === '4' && (
                <div className="login-box-wrap">
                  <div className="login-switch-wrap">
                    <CustomTabs active={this.state.loginType} onChange={this.handleItem}>
                      <CustomTabs.CustomTabPane label="注册" name="4"/>
                    </CustomTabs>
                  </div>
                  <div className="login-form-wrap">
                    <div className="login-form-cell">
                      <LoginInput
                        placeholder="请输入手机号"
                        icon="&#xe715;"
                        tips={this.state.registerFormRules.mobile.inputTips}
                        errorTips={this.state.registerFormRules.mobile.errorTips}
                        clearError={this.clearErrorTips.bind(this, 'registerFormRules', 'mobile')}
                        require={true}
                        onRef={this.registerInputOnRef}
                        maxLength={11}
                        value={this.state.registerForm.mobile}
                        onChange={this.inputChange.bind(this, 'registerForm', 'mobile')}/>
                    </div>
                    <div className="login-form-cell">
                      <LoginInput
                        placeholder="请输入图形验证码"
                        icon="&#xe6a2;"
                        tips={this.state.registerFormRules.imageCaptcha.inputTips}
                        errorTips={this.state.registerFormRules.imageCaptcha.errorTips}
                        clearError={this.clearErrorTips.bind(this, 'registerFormRules', 'imageCaptcha')}
                        require={true}
                        onRef={this.registerInputOnRef}
                        maxLength={4}
                        value={this.state.registerForm.imageCaptcha}
                        onChange={this.inputChange.bind(this, 'registerForm', 'imageCaptcha')}/>
                      <div className="captcha-wrap">
                        <img src={this.state.captcha} alt="" className="captcha"  onClick={this.createCaptcha.bind(this,'4')}/>
                      </div>
                    </div>
                    <div className="login-form-cell">
                      <LoginInput
                        placeholder="请输入短信验证码"
                        icon="&#xe62c;"
                        tips={this.state.registerFormRules.smsCaptcha.inputTips}
                        errorTips={this.state.registerFormRules.smsCaptcha.errorTips}
                        clearError={this.clearErrorTips.bind(this, 'registerFormRules', 'smsCaptcha')}
                        require={true}
                        onRef={this.registerInputOnRef}
                        maxLength={6}
                        value={this.state.registerForm.smsCaptcha}
                        onChange={this.inputChange.bind(this, 'registerForm', 'smsCaptcha')}/>
                      <SMSButton mobile={this.state.registerForm.mobile}/>
                    </div>
                    <div className="login-form-cell">
                      <LoginInput
                        placeholder="请输入密码"
                        icon="&#xe63a;"
                        tips={this.state.registerFormRules.password.inputTips}
                        errorTips={this.state.registerFormRules.password.errorTips}
                        clearError={this.clearErrorTips.bind(this, 'registerFormRules', 'password')}
                        require={true}
                        onRef={this.registerInputOnRef}
                        maxLength={20}
                        type="password"
                        value={this.state.registerForm.password}
                        onChange={this.registerPswChange.bind(this, 'password')}
                        onBlur={this.registerPswBlur}/>
                      {
                        this.state.registerPswTipsShow && <PasswordValidTips onRef={this.passwordInputOnRef}/>
                      }
                    </div>
                    <div className="login-form-cell">
                      <LoginInput
                        placeholder="请确认密码"
                        icon="&#xe63a;"
                        tips={this.state.registerFormRules.confirmPassword.inputTips}
                        errorTips={this.state.registerFormRules.confirmPassword.errorTips}
                        clearError={this.clearErrorTips.bind(this, 'registerFormRules', 'confirmPassword')}
                        require={true}
                        onRef={this.registerInputOnRef}
                        maxLength={20}
                        type="password"
                        value={this.state.registerForm.confirmPassword}
                        onChange={this.inputChange.bind(this, 'registerForm', 'confirmPassword')}/>
                    </div>
                    <div className="login-form-cell">
                      <LoginInput
                        placeholder="请输入邀请码"
                        tips={this.state.registerFormRules.invitationCode.inputTips}
                        errorTips={this.state.registerFormRules.invitationCode.errorTips}
                        clearError={this.clearErrorTips.bind(this, 'registerFormRules', 'invitationCode')}
                        require={true}
                        onRef={this.registerInputOnRef}
                        maxLength={6}
                        value={this.state.registerForm.invitationCode}
                        onChange={this.inputChange.bind(this, 'registerForm', 'invitationCode')}
                        onKeyUp={this.inputRegisterKeyUp}/>
                    </div>
                    <LoginButton onClick={this.registerSubmit}>注册</LoginButton>
                    <div className="login-form-links justify-left">
                      <label>
                        <input type="checkbox" checked={this.state.holdAccount.registerProtocol}
                               onChange={this.inputChange.bind(this, 'holdAccount', 'registerProtocol')}/>
                        <p className="icon-wrap">
                          <i className="iconfont">&#xe60f;</i>
                        </p>
                        <p className="text">我已阅读并同意</p>
                      </label>
                      <a className="login-form-protocol-button" href="#/login/register-protocol">《内推圈注册协议》</a>
                    </div>
                  </div>
                </div>
              )
            }
            {/*注册完成*/}
            {
              this.state.loginType === '5' && (
                <div className="login-box-wrap register-done-wrap">
                  <div className="register-done-icon">
                    <i className="iconfont">&#xe615;</i>
                  </div>
                  <p className="title">注册成功</p>
                  <p className="subtitle">请前往完善并认证个人信息</p>
                  <CustomButton width={140} onClick={this.toEditInfo}>完善个人信息</CustomButton>
                  <div className="contact-company-wrap">
                    <div className="company-qrcode-wrap">
                      <img src={require('../../images/connect-qrcode.jpeg')} alt=""/>
                    </div>
                    <div className="company-wechat-wrap">
                      <p className="scan-tips">扫码添加组织者微信</p>
                      <div className="contact-number-wrap">
                        <div className="icon-wrap">
                          <i className="iconfont">&#xe641;</i>
                        </div>
                        <p className="contact-number">itneituiquan123</p>
                      </div>
                      <div className="contact-number-wrap">
                        <div className="icon-wrap">
                          <i className="iconfont">&#xe6c3;</i>
                        </div>
                        <p className="contact-number">183 8046 8848</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
        <div className="copyright">copyright@{new Date().getFullYear()}内推圈</div>
      </div>
    )
  }
}

