import React from 'react';
import { Drawer } from 'antd';
import PositionDetail from '../PositionDetail'; // 引用自定义内容组件

const PositionDetailDraw = ({ visible, onClose, positionId }) => {
  return (
    <Drawer
      title="职位详情"
      width={1000} // 设置抽屉宽度
      onClose={onClose} // 关闭抽屉时触发的回调
      visible={visible} // 控制抽屉的显示隐藏
      destroyOnClose // 关闭时销毁内容以释放内存
    >
      {/* 将 id 传递给 PositionDetail 组件 */}
      <PositionDetail positionId={positionId} />
    </Drawer>
  );
};

export default PositionDetailDraw;
