import React, { useState } from "react"
import {Dialog,Button, Loading} from "element-react";
import CustomToast from '../../components/CustomToast'
import http from "../../api/http";
import api from "../../api";
import style from './index.module.scss';
import PropTypes from 'prop-types';

export default function JobShare(props){
    const [dialog,setDialog] = useState(false);
    const [shareTitle,setShareTitle] = useState('');
    const [shareImgsList,setShareImgsList] = useState([]);
    const [loading,setLoading] = useState(false);

    const onGetShareImg = async(type)=>{
        setShareTitle(type===1?'微信小程序分享图片':'QQ小程序分享图片');
        setShareImgsList([]);
        setLoading(true);
        let params ={
            positionId:props.id,
            scence:props.id,
            page:`/pages/index/jobdetail?id=${props.id}`,
            source:type
        };
        let res = await  http.get(api.sharedImgs, {params});
        setLoading(false);
        // eslint-disable-next-line eqeqeq
        if(res.code==200){
          setShareImgsList(res.data);
        }else{
          setShareTitle('');
          CustomToast(res.message);
        }
    }
    const onClose = ()=> {
        setDialog(false);
        setShareTitle('');
        setShareImgsList([]);
    }
    const btnProps = {};
    if(props.btnSize){
        btnProps.size = props.btnSize;
    }
    btnProps.type = props.btnType || 'primary';
    return(
        <>
            <div className={style['share-btn']} onClick={()=>setDialog(true)}>
              <Button icon="share" {...btnProps}>分享</Button>
            </div>
            <Dialog
                title="分享图片"
                visible={dialog}
                onCancel={onClose}
                lockScroll={false}
                customClass={style['dialog-share-box']}
            >
              <Loading loading={loading}>
                  <Dialog.Body>
                    <div className={style['dialog-share']}>
                      <div className={style['dialog-share-title']}>{shareTitle || '选择分享类型'}</div>
                      <div className={style['dialog-share-imgs']}>
                        {shareImgsList.map(value => <img src={value} alt="加载失败"></img>)}
                      </div>
                      <div className={style['dialog-share-btn']}>
                        <Button type="primary" icon="share" onClick={()=>onGetShareImg(1)}>微信小程序</Button>
                        <Button type="primary" icon="share" onClick={()=>onGetShareImg(2)}>QQ小程序</Button>
                      </div>
                    </div>
                  </Dialog.Body>
                </Loading>
              <Dialog.Footer className="dialog-footer">
                <Button size='small' onClick={onClose}>关闭</Button>
              </Dialog.Footer>
          </Dialog>
        </>
    )
}

JobShare.propTypes = {
  /** 职位id */
  id: PropTypes.number.isRequired,
  btnSize: PropTypes.string,
  btnType: PropTypes.string,
};