import axios from 'axios';
import { getCookieOne } from '../utils/index'
import { message } from 'antd'
import CustomToast from '../components/CustomToast'

function showToast(value) {
  // CustomToast(value)
  message.error(value)
}

// 定义一个全局常量
axios.defaults.timeout = 60000;
axios.interceptors.request.use(
  config => {
    config.headers.token = getCookieOne('token');
    return config;
  },
  error => {
    Promise.reject(error); // 错误提示
  }
);

axios.interceptors.response.use(
  response => {
    if(response instanceof Blob) return response;
    let res = response.data;
    if (res.code === 98030) {
      showToast("登录失效");
      window.location.hash = '#/login';
      return '';
    } else if (res && res.code !== 200) {
      // showToast(res.message || '系统异常');
    }
    return res;
  },
  error => {
    showToast("服务器响应失败");
    return Promise.reject(error);
  }
);

axios.jsonp = function(url) {
  return new Promise((resolve, reject) => {
    let key = "callback" + Date.now().toString();
    let script = document.createElement("script");
    script.src = url + "&callback=" + key;
    window[key] = res => {
      delete window[key];
      document.body.removeChild(script);
      resolve(res);
    };
    document.body.appendChild(script);
  });
};

export default axios;
