import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
/**
 * 检查用户是否签署了合同，没签合同强制弹窗
 */
function withContractGuard(WrappedComponent) {
  return function GuardedComponent(props) {
    const user = useSelector((state) => state.user?.userInfo) || {};
    const userIsNull = !user || Object.keys(user).length === 0;
    const ref = React.createRef();
    useEffect(() => {
        const {signState,accountType} = user;
        if(!ref.current && !userIsNull && signState!==2){
            ref.current = true;
            const title = '提示';
            if(accountType ===1){
                Modal.confirm({
                    title,
                    icon: <ExclamationCircleFilled />,
                    content: signState === 1 ? '合同正在签署中，请耐心等待' : '您的账号还未签约,请先去签约。',
                    okText: '去签约',
                    cancelText: '取消',
                    onOk() {
                        localStorage.setItem('userCurrentTab','8');
                        window.location.href='/#/home/user-center';
                    },
                });
            }
            else{
                Modal.info({
                    title,
                    okText: '知道了',
                    content: signState === 1 ? '合同正在签署中，请耐心等待' : '您的账号还未签约,请提醒主账号去签约。',
                });
            }
        }
    }, [user]);
    return <WrappedComponent {...props} />;
  };
}

export default withContractGuard;
