import React, { useState, useEffect } from "react";
import { Table, Pagination, message } from "antd";
import http from '../../../../api/http';
import api from '../../../../api';

const RecommendHistory = ({ positionId }) => {
  const [data, setData] = useState([]); // 表格数据
  const [loading, setLoading] = useState(false); // 加载状态
  const [pagination, setPagination] = useState({
    current: 1, // 当前页码
    pageSize: 10, // 每页条数
    total: 0, // 总条数
  });

  // 请求推荐历史数据
  const fetchRecommendHistory = async (pageIndex, pageSize) => {
    setLoading(true);
    try {
      const response = await http.get(api.getRecommendHistory(positionId), {
        params: {
          positionId,
          pageSize,
          pageIndex,
        }
      });
      if (response.code === 200) {
        const { pagination } = response;

        setData(response.data || []); // 设置表格数据
        setPagination({
          ...pagination,
          current: pageIndex,
          total: pagination.totalRow || 0, // 设置总条数
        });
      } else {
        message.error(response.message || "加载失败，请稍后重试！");
      }
    } catch (error) {
      message.error("加载推荐历史记录失败，请稍后重试！");
    } finally {
      setLoading(false);
    }
  };

  // 监听分页变化
  const handleTableChange = (page, pageSize) => {
    fetchRecommendHistory(page, pageSize);
  };

  // 初始化请求
  useEffect(() => {
    fetchRecommendHistory(pagination.current, pagination.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionId]); // positionId 变化时重新加载

  // 定义表格列
  const columns = [
    {
      title: "人选信息",
      dataIndex: "candidateInformation",
      key: "candidateInformation",
    },
    {
      title: "推荐结果",
      dataIndex: "deliveryResults",
      key: "deliveryResults",
    },
    {
      title: "目前公司和职位",
      dataIndex: "positionAndCompanyName",
      key: "positionAndCompanyName",
    },
    {
      title: "反馈日期",
      dataIndex: "feedbackDate",
      key: "feedbackDate",
    },
    {
      title: "反馈内容",
      dataIndex: "feedbackResults",
      key: "feedbackResults",
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey={(record, index) => index} // 为每一行设置唯一 key
        pagination={false} // 禁用表格内部分页
        loading={loading}
      />
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={handleTableChange}
        style={{ marginTop: 16, textAlign: "right" }}
      />
    </div>
  );
};

export default RecommendHistory;
