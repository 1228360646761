import React, { useEffect, useState } from 'react';
import { Tabs, Badge } from 'antd';
import http from '../../../../api/http'
import api from '../../../../api'
import JobItem from '../../../../components/JobItem'
import PositionInfo from "../PositionInfo";
import CompanyInfo from "../CompanyInfo";
import RecruitmentPreview from "../RecruitmentPreview";
import RecommendHistory from "../RecommendHistory";
import RecommendFloat from "../../../../components/RecommendFloat";
import CompanyRequirementInfo from '../../../company/components/CompanyRequirementInfo';
import './index.scss'
const { TabPane } = Tabs;
const PositionDetail = ({ positionId, active = '1', showRecommend = false }) => {
  const [isRed, setIsRed] = useState(false);
  const [num, setNum] = useState(5);
  const [positionInfo, setPositionInfo] = useState({})
  const [activeTab, setActiveTab] = useState('1'); // 默认选中的 Tab
  const [positionRequireId, setPositionRequireId] = useState(''); // 默认选中的 Tab
  useEffect(() => {
    if (active) {
      setActiveTab(active)
    }
    const getPositionInfo = async (id) => {
      const res = await http.get(api.getSinglePosition(id));
      if (res.code === 200) {
        const red = Boolean(res.data.previewRequired);
        setPositionInfo(res.data)
        setIsRed(red)
        setNum(res.data.deliveryPassCounts)
        setPositionRequireId(res.data.positionRequireId)
      }
    }
    if (positionId) {
      getPositionInfo(positionId)
    }
  }, [positionId, active])
  // 上报已读
  useEffect(() => {
    if (active === '3' && positionRequireId) {
      setRequireRead(positionRequireId)
    }
  }, [active, positionRequireId])
  const JobCollectCb = (cbData) => {
    setPositionInfo({
      ...positionInfo,
      collectionPosition: cbData.type,
      collectorNumber: cbData.collectorNumber
    })
  }
  const setRequireRead = async () => {
    if (!positionRequireId) return;
    http.put(api.setRequireRead(positionRequireId))
      .then(res => {
        if (res.code === 200) {
          setIsRed(false);
        }
      })
  }
  const onTabChange = (key) => {
    setActiveTab(key);
    if (key === '3') {
      setRequireRead();
    }
  };
  return <div style={{ background: '#fff', padding: '10px', borderRadius: '4px' }}>
    <div style={{ width: '100%' }}>
      <JobItem
        data={positionInfo}
        showRecommend
        showCompanyName={false}
        callback={JobCollectCb}
      />
      <div className="position-wrapper" style={{ width: '100%' }}>
        <div className="position-content mr-20">
          <Tabs activeKey={activeTab} onChange={onTabChange}>
            {/* 职位信息 */}
            <TabPane tab="职位信息" key="1">
              <div>
                <PositionInfo positionInfo={positionInfo} />
              </div>
            </TabPane>
            {/* 需求分析，显示小红点 */}
            <TabPane
              tab={
                <Badge dot={isRed}>
                  <span>职位需求分析</span>
                </Badge>
              }
              key="2"
            >
              <div>
                <RecruitmentPreview positionId={positionId}/>
              </div>
            </TabPane>
            {/* 公司信息 */}
            <TabPane tab="公司信息" key="3">
              <div>
                <CompanyInfo companyId={positionInfo.companyId} />
              </div>
            </TabPane>
            {/* 公司需求分析 */}
            <TabPane tab="公司需求分析" key="4">
              <div>
                <CompanyRequirementInfo companyId={positionInfo.companyId} />
              </div>
            </TabPane>

            {/* 推荐历史记录，显示数字 */}
            <TabPane
              tab={
                <span>推荐历史记录({num})</span>
              }
              key="5"
            >
              <div>
                <RecommendHistory positionId={positionId} />
              </div>
            </TabPane>
          </Tabs>
        </div>
        {
          showRecommend && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <div className="position-float">
                <RecommendFloat title="紧急职位" positionId={positionId} type="urgent"/>
              </div>
              <div className="position-float mt-10">
                <RecommendFloat title="推荐职位" positionId={positionId} type="recomend"/>
              </div>
            </div>

          )
        }
      </div>
    </div>
  </div>;
}
export default PositionDetail;
