import React, { useEffect, useRef, useState } from "react";
import api from "@/api/index.js";
import http from "@/api/http.js";
import BindPhone from "../BindPhone";
import style from './index.module.scss';

const NOT_SCAN = "222";
const NO_PHONE = "777";
const HAS_PHONE = "888";

export default function WechatCode({ onSucceed,mobile,styleObj={} }) {
  const timerRef = useRef();
  const [showBindPhone, setShowBindPhone] = useState(false);
  const [qrResult, setQrResult] = useState({});
  const getQrcode = async () => {
    const res = await http.get(api.getQrcode());
    if (res.code === 200) {
      setQrResult({
        qrcode: res.data.url,
        ticket: res.data.ticket,
      });
    }
  };
  const checkWxLogin = async () => {
    const res = await http.post(api.checkWxLogin(), {
      ticket: qrResult.ticket,
      type: "headhunter",
    });
    if (res.code === 200) {
      const { stateCode } = res.data;
      if (stateCode !== NOT_SCAN) {
        if(timerRef.current) clearInterval(timerRef.current);
        if (stateCode === NO_PHONE) {
          setShowBindPhone(true);
        } else if (stateCode === HAS_PHONE) {
          onSucceed(res.data.accessToken);
        }
      }
    }
  };
  useEffect(() => {
    if (Object.keys(qrResult).length > 0) {
      timerRef.current = setInterval(checkWxLogin, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrResult]);
  useEffect(() => {
    getQrcode();
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);
  return (
    <>
        <BindPhone
          visible={showBindPhone}
          onCancel={() => setShowBindPhone(false)}
          ticket={qrResult?.ticket}
          onSucceed={onSucceed}
          mobile={mobile}
        />
        <div className={style['qrcode-wrapper']} style={styleObj}>
          {qrResult?.qrcode && <img src={qrResult?.qrcode} alt="" />}
        </div>
    </>
  );
}
