import React, { Component } from 'react'
import CustomToast from '../../components/CustomToast'
import http from '../../api/http.js'
import api from '../../api/index.js'
import {getQueryParams} from "../../utils";


export default class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      queryParams: getQueryParams(this.props.location.search)
    }
  }

  componentDidMount() {
    this.bindWechat()
  }

  // 获取用户信息
  bindWechat = () => {
    http.get(api.wechatBind, {
      params: {
        code: this.state.queryParams.code,
        state: 'STATE'
      }
    })
      .then(res => {
        if (res.code === 200) {
          window.location.replace(`${window.location.origin}/#/supplement-user-info?step=2`);
        } else {
          CustomToast(res.message)
        }
      })
  }


  render() {
    return (
      <div/>
    )
  }
}
