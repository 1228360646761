import React, { useEffect, useState } from 'react';
import http from "../../../../api/http";
import api from "../../../../api/index";
import { Map, Marker, InfoWindow } from 'react-amap';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getQueryParamNew } from '../../../../utils';

const CompanyInfo = ({ companyId, hideBaseInfo = false }) => {
  const userInfo = useSelector((state) => state.user?.userInfo) || {};
  const [companyInfo, setCompanyInfo] = useState({})
  const [position, setPosition] = useState({
    longitude: 0,
    latitude: 0
  })
  const fetchData = async () => {
    const res = await http.get(api.getCompanyInfo(companyId))
    if (res.code === 200) {
      setCompanyInfo(res.data)
      setPosition({
        longitude: res.data.longitude,
        latitude: res.data.latitude
      })
    }
  }
  useEffect(() => {
    if (companyId) {
      fetchData()
    }
  }, [companyId])
  const clearInfo = (()=>{
      if(userInfo?.signState === 2) return true;
      const ui = getQueryParamNew('ui');
      return ui == 2;
  })() 
  return <div>
    <div>
      {
        hideBaseInfo ? null : (
          <>
            <h3 style={{margin: '0 0 10px 0', fontSize: '16px'}}>公司基本信息</h3>
            {/* 模块1：公司信息 */}
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
              {/* 左侧：公司头像 */}
              <div className={classNames({'img-dim':!clearInfo})}>
                <img
                  src={companyInfo.avatarUrl}
                  alt="公司头像"
                  style={{width: '52px', height: '52px', borderRadius: '4px', objectFit: 'cover'}}
                />
              </div>
              {/* 右侧：公司信息 */}
              <div style={{marginLeft: '10px'}}>
                <h2 style={{margin: '0'}} className={classNames({'text-dim':!clearInfo})}>{companyInfo.nameCn}</h2>
                <p style={{margin: '5px 0', color: '#555'}}>
                  {companyInfo.scaleTypeName} | {companyInfo.industryTypeName}
                </p>
              </div>
            </div>
          </>
        )
      }
      {/* 模块3：公司简介 */}
      <div style={{marginBottom: '40px'}}>
        <h3 style={{margin: '10px 0 10px 0', fontSize: '16px', fontWeight: 'bold'}}>公司简介</h3>
        <div
          style={{color: '#333'}}
          className={classNames({'text-dim':!clearInfo})}
          dangerouslySetInnerHTML={{__html: companyInfo.description}}
        ></div>
      </div>
      {/* 模块2：公司地址 */}
      <div style={{marginBottom: '20px'}}>
        <h3 style={{margin: '10px 0 10px 0', fontSize: '16px', fontWeight: 'bold'}}>公司地址</h3>

        <p style={{margin: '0', color: '#333'}} className={classNames({'text-dim':!clearInfo})}>{companyInfo.address}</p>
      </div>
      <div style={{width: 500, height: 360}} className={classNames('mb-20',{'text-dim':!clearInfo})}>
        {position?.latitude && position?.longitude &&  (
          <Map zoom={16} center={position} amapkey="d6a2582cc583f830d58dfdde59c8c010" version={'1.4.4'}>
            <InfoWindow position={position} visible isCustom>
              {
                companyInfo.address && (
                  companyInfo.address
                )
              }
            </InfoWindow>
            <Marker position={position}/>
        </Map>
        )}
      </div>
    </div>

  </div>;
}
export default CompanyInfo;
