import React from 'react';
import { Row, Col } from 'antd'
import './index.scss'
import InfoDisplay from "../../../../components/InfoDisplay";
const PositionInfo = ({ positionInfo }) => {
  return <div>
    <h1>推荐信息</h1>
    <div className="mt-10 mb-10">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8}>
          <InfoDisplay title="约面人数" value={positionInfo.haveAboutTheInterview}/>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <InfoDisplay title="offer数" value={positionInfo.offernumber}/>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <InfoDisplay title="推荐人数" value={positionInfo.recommendedNumber}/>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <InfoDisplay title="接单人数" value={positionInfo.receiptsNumberl}/>
        </Col>
      </Row>
    </div>
    <h1>基本要求</h1>
    <div className="mt-10 mb-10">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8}>
          <InfoDisplay title="工作详细地址" value={positionInfo.address}/>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <InfoDisplay title="工作年限要求" value={positionInfo.workExperienceName}/>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <InfoDisplay title="薪水范围" value={positionInfo.salaryRangeName}/>
        </Col>
      </Row>
    </div>
    <h1>职位描述</h1>
    <div className="multi-text">
      <div dangerouslySetInnerHTML={{__html: positionInfo.desc}} style={{ whiteSpace: 'pre-wrap' }}/>
    </div>

    <h1>任职要求</h1>
    <div className="multi-text">
      <div dangerouslySetInnerHTML={{__html: positionInfo.requirements}} style={{ whiteSpace: 'pre-wrap' }}/>
    </div>
  </div>;
}
export default PositionInfo;
