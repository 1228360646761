import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: null, // 初始化用户信息为空
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.userInfo = action.payload; // 设置用户信息
    },
    clearUserInfo(state) {
      state.userInfo = null; // 清空用户信息
    },
  },
});

export const { setUserInfo, clearUserInfo } = userSlice.actions;
export default userSlice.reducer;
