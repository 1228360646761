/**
 * Created by Jachin on 2019/4/11.
 */

/**
 * Toast组件
 * */
import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import './index.scss'

class Index extends Component {
  constructor (props) {
    super(props)
    this.state = {
      delay: 3000
    }
  }

  render () {
    return (
      <div className="custom-toast">{this.props.text}</div>
    )
  }
}

const Toast = function (params) {
  let text = '';
  let delay = 3000;
  if (typeof params === 'string') {
    text = params
  } else {
    text = params && params.text;
    if (params && params.delay) {
      delay = params.delay;
    }
  }
  let beforeDiv = document.getElementById('custom-toast');
  if (beforeDiv) {
    clearTimeout(beforeDiv.timer);
    ReactDOM.unmountComponentAtNode(beforeDiv);
    document.body.removeChild(beforeDiv);
  }
  const div = document.createElement('div');
  div.id = 'custom-toast';
  document.body.appendChild(div);
  ReactDOM.render(<Index text={text}/>, div);
  div.timer = setTimeout(() => {
    ReactDOM.unmountComponentAtNode(div);
    document.body.removeChild(div);
  }, delay)
}
export default Toast;
