/**
 * Created by Jachin on 2019/4/11.
 */

/**
 * 页面中间内容的组件；
 *
 * */
import React, { Component } from 'react'
import './index.scss'

export default class Index extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    return (
      <div className="content-wrapper">{this.props.children}</div>
    )
  }
}

