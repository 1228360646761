import React from "react";
import './index.scss';
const InfoDispaly = ({ title, value,isRichText = false }) => {
  return <div className="info-display">
    <span className="info-display-title">{ title }: </span>
    {(!isRichText) && <span className="info-display-value">{ value }</span>}
    {isRichText && <div className="info-display-value" dangerouslySetInnerHTML={{__html:value}}></div>}
  </div>
}
export default InfoDispaly;
