import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

const JobItemMini = ({ info }) => {
  const userInfo = useSelector((state) => state.user?.userInfo) || {};
  return (
    <div className="job-item-mini border-bottom" style={{ paddingBottom: 10, paddingTop: 10 }}>
      <h3 className={'single-ellipsis'}>
        <a href={"#/home/jobs/job-detail?id=" + info.id} className={"color-primary"}>{ info.name }</a>
      </h3>
      <p>{ info.salaryRangeName } | 招聘{ info.numbers }人 | { info.financeTypeName } | {info.qualificationsName}</p>
      <p className={classNames({'text-dim':userInfo?.signState!==2})}>{info.companyName}</p>
    </div>
  )
}
export default JobItemMini
