/**
 * Created by Jachin on 2019/4/11.
 */

/**
 * 职位列表 item 组件
 * */
import React, { Component } from 'react'
import './index.scss'
import CustomToast from '../../components/CustomToast'
import http from "../../api/http";
import api from "../../api";
import {Tooltip,Dialog,Button,Loading} from "element-react";
import NoticePanel from '../../components/NoticePanel';


export default class Index extends Component {
  constructor (props) {
    super(props)
    this.state = {
      collectSubmitLoading: false,
      dialogVisible:false,
      cancleDialogVisible:false,
      shareImgVisible:false,  //分享弹框
      shareTitle:'',//分享标题
      shareImgsList:[] ,
      pageLoading:false
    }
  }

  // 前往公司详情页
  toCompanyHome = () => {
    window.open(`#/home/company/company-detail?companyId=${this.props.data.companyId}`, '_blank');
  }

  // 前往职位详情
  toPositionDetail = () => {
    window.open(`#/home/jobs/job-detail?id=${this.props.data.id}`, '_blank');
  }

  // 过滤发布时间
  filterDate = (date) => {
    return new Date(date).Format('MM/dd hh:mm')
  }

  // 点击收藏和取消收藏
  collect = async () => {

    if (this.state.collectSubmitLoading) {
      return false
    }

    if (this.props.data.collectionPosition) {
      // 取消收藏
      this.setState({
        collectSubmitLoading: true,
        cancleDialogVisible:true,
      });

    } else {
      // 收藏
      this.setState({
        collectSubmitLoading: true,
        dialogVisible:true
      });

    }
  }

  //确定收藏职位
  collectConfirm = async ()=>{
    let res = await http.post(api.collect, {positionId: this.props.data.id}, {
    });
    if (res.code === 200 && this.props.callback) {
      CustomToast("接单成功！");
      let cbData = {
        type: true,
        collectorNumber: res.data
      }
      this.props.callback(cbData, this.props.data);
      this.props.onCancel();
    }else{
      CustomToast(res.message)
    }

    // this.setState({
    //   collectSubmitLoading: false,
    //   dialogVisible:false
    // });

  }


  //取消收藏职位
  canclecollectConfirm = async()=>{
    let res = await http.delete(api.collectDel(this.props.data.id));
      if (res.code === 200 && this.props.callback) {
        CustomToast("取消接单成功！");
        let cbData = {
          type: false,
          collectorNumber: ''
        }
        this.props.callback(cbData, this.props.data);
        this.props.onCancel();
      }else{
        CustomToast(res.message);
      }
      // this.setState({
      //   collectSubmitLoading: false,
      //   cancleDialogVisible:false
      // });


  }

  //显示分享图片弹框
  shareImgs = async ()=>{
    console.log(11111)
    this.setState({
      shareImgVisible:true
    });
    console.log(this.state.showShareImg,88)
  }

  //获取分享图片
  getShareImg = async(type)=>{
    this.setState({
      shareTitle:type==1?'微信小程序分享图片':'QQ小程序分享图片',
      pageLoading:true,
      shareImgsList:[]
    })
    let params ={
        positionId:this.props.data.id,
        scence:this.props.data.id,
        page:`/pages/index/jobdetail?id=${this.props.data.id}`,
        source:type
    };
    console.log(params,88)

    let res = await  http.get(api.sharedImgs, {params});
    this.setState({
      pageLoading:false
    })
    console.log(res,999)
    if(res.code==200){
      this.setState({
        shareImgsList:res.data,
      })
    }else{
      this.setState({
        shareTitle:''
      })
      CustomToast(res.message);
    }



  }



  render () {
    if (this.props.data) {
      return (
        <div className="job-item-dialog">

          {
            this.props.dialogType===1? <Dialog
            title="提示"
            visible={ this.props.dialogVisible }
            onCancel={ this.props.onCancel }
            lockScroll={ false }
          >
            <Dialog.Body>
              <div className='dialog-notice'>
                <i className="el-icon-warning"></i>
                <p>请注意！在接单该职位后，您可以在该岗位上推荐合适的候选人</p>
              </div>
              <NoticePanel data={this.props.data}></NoticePanel>
              <div className='dialog-explain'>
              PS：<strong>请注意！</strong>
              当您接单该职位以后，我们将在该职位的状态发生变化、职位内容发生变化、需求分析发生变化、薪水范围发生变化的时候我们将会以短信、邮件、微信公众号消息（暂未开放）的时候通知你。
              </div>
            </Dialog.Body>
            <Dialog.Footer className="dialog-footer">
              <Button onClick={ this.props.onCancel}>取消</Button>
              <Button type="primary" onClick={ this.collectConfirm} loading={this.state.collectSubmitLoading}>继续接单该职位</Button>
            </Dialog.Footer>
          </Dialog> : <Dialog
            title="提示"
            visible={ this.props.dialogVisible }
            onCancel={ this.props.onCancel }
            lockScroll={ false }
          >
            <Dialog.Body>
              <div className='dialog-notice'>
                <i className="el-icon-warning"></i>
                <p>请注意！您取消接单该职位以后，将不能给该职位推荐候选人，也不会在该职位的状态、内容、需求分析、薪水范围发生变化的时候使用短信、邮件、我微信公众号（暂未开放）的时候通知你，但是不影响查看职位详情、过往推荐记录。</p>
              </div>
            </Dialog.Body>
            <Dialog.Footer className="dialog-footer">
              <Button onClick={ this.props.onCancel }>取消</Button>
              <Button type="primary" onClick={ this.canclecollectConfirm} loading={this.state.collectSubmitLoading}>继续取消接单该职位</Button>
            </Dialog.Footer>
          </Dialog>
          }





          {
            this.props.children
          }
        </div>
      )
    }
  }
}

