import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col } from 'antd'
import './index.scss'
import InfoDisplay from "../../../../components/InfoDisplay";
import http from "../../../../api/http";
import api from "../../../../api";
import { useSelector } from "react-redux";
import classNames from 'classnames';
import { getQueryParamNew } from '../../../../utils';

/** 公司整体需求分析 */
const CompanyRequirementInfo = ({ companyId }) => {
  const [companyInfo, setCompanyInfo] = useState({});
  const [fileList, setFileList] = useState([]);

  const getSuffix = async () => {
    const res = await http.get(api.getSuffix(companyId))
    if (res.code === 200) {
      setFileList(res.data || []);
    }
  }
  const fetchData = async () => {
    try {
      const res = await http.get(api.getCompanyRequirements(companyId));
      if (res.code === 200) {
        setCompanyInfo(res.data);
      } else {
        console.error("Error fetching data:", res.message);
      }
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };
  const userInfo = useSelector((state) => state.user?.userInfo) || {};

  useEffect(() => {
    if (companyId) {
      fetchData();
      getSuffix()
    }
  }, [companyId]);
  
  const clearInfo = useMemo(()=>{
    if(userInfo?.signState === 2) return true;
    const ui = getQueryParamNew('ui');
    return ui == 2;
  },[userInfo]);
  
  return <div className={classNames({'text-dim':!clearInfo})}>
    <h1>基本信息</h1>
    <div className="mt-10 mb-10">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8}>
          <InfoDisplay title="年龄要求" value={companyInfo.ageRequirement}/>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <InfoDisplay title="其他学历要求" value={companyInfo.ohterEducationalName}/>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <InfoDisplay title="语言要求熟练程度" value={companyInfo.languageProficiencyRequirements}/>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <InfoDisplay title="工作年限要求" value={companyInfo.workYear}/>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <InfoDisplay title="其他需求分析" value={companyInfo.otherAnalysis} isRichText={true}/>
        </Col>
      </Row>
    </div>
    <h1>需求附件</h1>
    <div className="mt-10 mb-10">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24}>
          {
            fileList.map(item => (
              <div key={item.id}>
                <div>
                  <a href={item.url} target="_blank" className={'color-primary'}>{item.suffix}</a>
                </div>
              </div>
            ))
          }
        </Col>
      </Row>
    </div>
  </div>;
}
export default CompanyRequirementInfo;
